<template>
  <div>

    <!-- User Info: Input Fields -->
    <validation-observer ref="formUploadProducts">

      <b-form
        @submit.prevent="uploadSims">
        <b-row>

          <!-- Field: Username -->
          <b-col
            cols="12"
            md="4"
          >

            <b-form-group
                label="Seriales sims"
                label-for="serial"
                description="Selecciona el archivo con los seriales correspondientes"
              >
              <validation-provider
                  #default="{ errors }"
                  name="Seriales sims"
                  rules="required"
                >

                <!-- Styled -->
                <b-form-file
                  v-model="fileSims"
                  placeholder="Selecciona..."
                  drop-placeholder="Drop file here..."
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>

          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            
            <b-form-group
              label="Operador"
              label-for="operator"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Operador"
                  rules="required"
                >

                <b-form-select
                  id="operator"
                  v-model="operator"
                  :options="operators"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              
            </b-form-group>

          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            
            <b-form-group
              label="Tamaño"
              label-for="size"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Tamaño"
                  rules="required"
                >

                <b-form-select
                  id="size"
                  v-model="size"
                  :options="sizes"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              
            </b-form-group>

          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Descripción"
              label-for="description"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Descripción"
                  rules="required"
                >

                <b-form-input
                  type="text"
                  id="description"
                  v-model="description"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>

          </b-col>
          
        </b-row>

        <!-- Action Buttons -->
        <b-spinner
          v-show="loading"
          variant="primary"
          label="Loading..."
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
           />

        <b-button
          v-show="!loading"
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          Subir
        </b-button>
      
      </b-form>
    </validation-observer>

    
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormFile, BFormSelect, BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { required, } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {constants} from "@/helpers/constants"

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BFormSelect,
    BSpinner,
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken'),
      fileSims: null,
      productTypeId: '',
      numberOrder: '',
      description: '',
      loading: false,
      required,
      operators: [
        {
          text: 'Selecciona una opción',
          value: null,
          disabled: true,
        },
        {
          text: 'CLARO',
          value: 'CLARO',
        },
        {
          text: 'MOVISTAR',
          value: 'MOVISTAR',
        },
      ],
      sizes: [
        {
          text: 'Selecciona una opción',
          value: null,
          disabled: true,
        },
        {
          text: 'PEQUEÑA',
          value: 'PEQUEÑA',
        },
        {
          text: 'GRANDE',
          value: 'GRANDE',
        },
      ],
      operator: '',
      size: '',
    }
  },
  setup(props) {
    const countryOptions = [
      { label: 'Colombia', value: 1 },
    ]

    const serializedOptions = [
      { label: 'Texto', value: 0 },
      { label: 'JSON', value: 1 },
    ]

    return {
      countryOptions,
      serializedOptions,
    }
  },
  created() {
  },
  methods: {
    uploadSims() {
      event.preventDefault()
      this.$refs.formUploadProducts.validate().then(success => {
        if (success) {

          this.loading = true

          let headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + this.accessToken
          }

          let formData = new FormData()
          formData.append('sims', this.fileSims)
          formData.append('description', this.description)
          formData.append('operator', this.operator)
          formData.append('size', this.size)

          axios.post(this.host + '/api/front-web/sims-import', formData, { headers }).then(response => {
            console.log('sims-import: ', response)

            this.loading = false

            if(response.data.status) {

              this.fileSims = null
              this.description = ''

              this.$toast({
                component: ToastificationContentVue,
                position: 'top-right',
                props: {
                  title: `Satisfactorio`,
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })

            } else {
              this.$toast({
                component: ToastificationContentVue,
                position: 'top-right',
                props: {
                  title: `Advertencia`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
            }
          }).catch( err => {
            console.log(err)
          })

        }
      })


    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
